import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { makeStyles } from "@material-ui/styles"
import LogoSvg from "../components/icons/logoSvg";
import Navbar from "../components/navbar/navbar";
import FacebookLogo from "../components/icons/facebookLogo";
import InstagramLogo from "../components/icons/instagramLogo";
import ExternalLink from "../components/links/ExternalLink";

const useStyles = makeStyles({
  container: {
    backgroundColor: '#192C4F',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoContainer: {
    width: '85%',
  },
  socialMediaConatiner: {
    position: 'fixed',
    height: 100,
    width: '100%',
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

// v.1
const IndexPage = () => {

const classes = useStyles();

 return (<Layout>
    <SEO 
      title="Målare i Åre, Järpen, Jämtland. Fasad, Interiör mm. - The Måler"
      description="The Måler gör måleriarbeten inom Åre-området med fokus hög kvalitet och yrkesstolthet. Verksam målare sedan 2016 - Gesällbrev - Rot-avdrag. Renovering fräschar upp."
    />
    <div className={classes.container}>
      <Navbar page="LANDING" />
      <div className={classes.logoContainer}>
        <LogoSvg></LogoSvg>
      </div>
      <div className={classes.socialMediaConatiner}>
        <div style={{paddingRight: 32}}>
         <ExternalLink alt="The Måler Facebook" href="https://www.facebook.com/themaler/">
           <FacebookLogo fill="#FCCFC9" />
         </ExternalLink>
        </div>
        <div>
         <ExternalLink alt="The Måler Instagram" href="https://www.instagram.com/the_maler_i_are/">
           <InstagramLogo fill="#FCCFC9" />
         </ExternalLink>
        </div>
      </div>
    </div>
  </Layout>
)
}

export default IndexPage
